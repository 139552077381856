$public_url: "/portal";

// $public_url: "";

@-moz-document url-prefix() {
  html,
  body {
    overflow: scroll !important;
  }
}

// ::-webkit-scrollbar {
//   // background: transparent; /* optional: just make scrollbar invisible */
//   scrollbar-width: thin;
// }
// ::-webkit-scrollbar {
//   scrollbar-width: thin;
// }
// ::-moz-scrollbar {
//   scrollbar-width: thin;
// }
input[type="number"] {
  &,
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover,
    &:focus {
      -moz-appearance: number-input;
    }
  }
}

// UBUNTU fonts

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url($public_url + "/fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url($public_url + "/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url($public_url + "/fonts/SourceSansPro-Regular.ttf") format("truetype");
}

$twenty: 20px;
$eighteen: 18px;
$sixteen: 16px;
$fifteen: 15px;
$fourteen: 14px;
$thirteen: 13px;
$twleve: 12px;
$ten: 10px;

$main-color: #1f317f;
$secondary-color: #da251d;
$span-color: #f9d8d7;
$light-header-color: #8b8b8b;
$diff-black-color: #2d3033;
$main-text-color: #293139;
$input-name-color: rgba(0, 0, 0, 0.7);
$headings-color: #26303c;
$button-color: #1f317f;

$semi-bold: "SourceSansPro-SemiBold";
$regular: "SourceSansPro-Regular";
$bold: "SourceSansPro-Bold";
.white-line {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 48.44%,
    rgba(255, 255, 255, 0) 96.87%
  );
  opacity: 0.7;
  width: 1px;
}
.custom-div {
  padding: 40px;
  height: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.custom-div a {
  color: #007bff;
  text-decoration: none;
}

.custom-div a:hover {
  text-decoration: underline;
}

.red-tool-bar {
  background-color: $secondary-color;
  width: 100%;
  display: flex;
  justify-content: center;

  .name {
    font-family: $regular;
    font-weight: 400;
    font-size: 13px;
    padding: 0px 10px;
    color: white;
  }
}
/* Square radio button styles */
.square-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border: 2px solid #555;
  border-radius: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 5px; /* Adjust spacing */
}

/* Checked state styles */
.square-radio:checked {
  background-color: #f50057;
}

/* Tick mark inside checked square radio button */
.square-radio:checked::after {
  content: '\2713'; /* Unicode checkmark character */
  font-size: 14px; /* Adjust size as needed */
  color: white;
  position: relative;
  top: -2px; /* Adjust vertical alignment */
  left: 3px; /* Adjust horizontal alignment */
}


.login-badges {
  .downaload-div {
    display: flex;
    margin: 40px 0px 20px 0px;
    align-items: center;
    .left {
      width: 100%;
      height: 2px;
      flex-grow: 1;
      flex-basis: 0;
      background: black;
      margin-right: 5px;
    }

    .middle {
      font-family: $semi-bold;
      font-size: 15px;
    }
    .right {
      width: 100%;
      height: 2px;
      flex-grow: 1;
      flex-basis: 0;
      background: black;
      margin-left: 5px;
    }
  }
  .badges {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.transcation-summary-responsive-page {
  padding: 20px;
}

.feedback-margin {
  margin: 20px 10px;
}
.number-rating {
  padding: 20px 100px;
  border-bottom: 1px solid #e7f2f9;
}
.non-login-pop-up-paper {
  padding: 10px;
  display: flex;
  margin: 10px 5px;
  align-items: center;
  .pop-up-title {
    font-size: 13px;
    font-family: $regular;
    font-weight: 400;
    margin-left: 10px;
  }
}
.home-bar {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $regular;
    padding: 0px 10px;
    color: black !important;
  }
  a:hover {
    color: $main-color !important;
  }

  button {
    border: 1px solid #3395d3;
    padding: 7px 10px;
    color: #3395d3;
    font-size: 14px;
    font-family: $regular;
    font-weight: 400;
    border-radius: 3px;
    background: transparent;
    margin: 0px 15px 0px 15px;
  }
}
.euin-text {
  color: rgba(128, 128, 128, 0.7);
  font-family: $regular;
  font-weight: 400;
  text-align: justify;
  margin: 10px 0px;
}
.display-simple-cards-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.simple-display-cards-details {
  margin: 0px 20px;
}
.simple-display-card-image {
  height: 35px;
}
.simple-display-card-name {
  font-size: $fourteen;
  font-family: $regular;
  font-weight: 400;
}
.transaction-status-cards {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  background-color: #e5f2f9;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.transaction-status-cards-mailback {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  background-color: #e5f2f9;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.transaction-status-cards:hover {
  background-color: #d1dde3;
  transition: 0.5s;
}
.investors-inactive-active-responsive {
  padding: 20px;
}

.App {
  text-align: center;
}
.container-padding {
  padding: 30px 50px;
}
.dual-margin {
  margin: 0px 15px;
}

// .display-cards-paper {
//   display: flex;
//   width: 33%;
//   transition: 0.4s;
//   height: 100px;
//   margin: 0px 10px;
//   background-color: white;
// }
// .display-cards-paper:hover {
//   box-shadow: 0px 5px 20px 0px rgba(175, 177, 177, 0.4);
//   -webkit-transform: translateY(-5px);
//   -ms-transform: translateY(-5px);

//   transform: translateY(-5px);
// }

.appbar-div {
  flex-grow: 1;
  flex-basis: 0px;
}
.logo {
  height: 100px;
}
.second_logo {
  height: 80px;
}
.transaction-digital-mode-paper {
  width: 50%;
  margin: 30px 100px 30px 100px;
  padding: 10px 0px;
}
.transaction-physical-mode-paper {
  width: 50%;
  margin: 30px 100px 30px 10px;
  padding: 10px 0px;
}
.password-regualations {
  color: gray;
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
  margin-top: 5px;
  padding-left: 5px;
}
.pass-star {
  color: lightblue;
}
.nav-right-container {
  width: 50%;
  background-color: #fbfcfd;
}
.total-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.container-margin {
  margin: 15px 50px;
}

// .carousel-indicators {
//   opacity: 0 !important;
//   display: none !important;
// }

.carsouel-div {
  width: 50%;
  height: 88vh;

  position: relative;
  .carousel-inner {
    margin-bottom: 30px;
    background-color: white;
    .carousel-item {
      height: 88vh;

      // display: contents;
    }
  }
  .carousel-indicators {
    bottom: 67px;
  }
}

.carsouel {
  height: 100%;
  object-fit: contain;
}
.login-container {
  height: 100vh;
  overflow: hidden;
}
.login-title {
  font-size: 35px;
  margin-bottom: 25px;
  letter-spacing: 1px;
  font-family: $bold;
  color: $headings-color;
}
.login-right-div {
  width: 50%;
  padding: 70px 150px 100px 100px;
}
.image {
  height: 50px;
  transform: inherit;
}
.login-facebook {
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: white;
  background-color: #3b5998;
  border: 1px solid $main-color;
  padding: 10px 15px;
}
.social-button {
  display: flex;
  justify-content: flex-start;
}
.login-google {
  margin-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid lightgray;
  padding: 10px 15px;
}

.digital-form-button-next-div {
  display: flex;
  justify-content: center;
}
.login-sigin-fbgoogle,
.login-sigin-or {
  padding: 0px 15px;
  font-size: $fifteen;
  font-family: $regular;
  font-weight: 400;
}

.login-sigin-or {
  color: gray;
}
.line-login-left {
  height: 1px;
  width: 100%;
  background-color: lightgray;
}
.line-login-right {
  height: 1px;
  width: 100%;
  background-color: lightgray;
}
.login-line {
  padding-right: 10px;
  display: flex;
  margin: 30px 100px;
  align-items: center;
  justify-content: space-between;
}
.input-name {
  font-size: $sixteen;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: $regular;
  font-weight: 400;
}

.terms-conditions {
  text-align: center;
  font-family: $regular;
  font-weight: 400;
  font-size: $fifteen;
  color: $main-color;
  text-decoration: underline;
  width: 100%;
}

.Input {
  width: 100%;
  font-size: $fourteen;
  font-family: $regular;
  font-weight: 400;
  padding: 14px 0px 14px 10px;
  margin: 10px 0px 0px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.login-forgot {
  display: flex;
  justify-content: flex-end;
  font-size: $fourteen;
  color: $input-name-color;
  margin-top: 10px;
  font-family: $regular;
  font-weight: 400;
}

.nav-left-container {
  width: 50%;
  padding: 30px 50px;
}

.login-button-sign-in {
  width: 100%;
  background-color: #1f317f;
  border: 1px solid #1f317f;
  box-shadow: 0px 0px 7px rgba(139, 139, 139, 0.256337);
  text-align: center;
  padding: 10px 0px;
  color: white;
  font-size: $fifteen;
  margin-top: 30px;
  font-family: $bold;
  border-radius: 5px;
}
.login-no-account {
  font-size: $fifteen;
  font-family: $regular;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  color: $headings-color;
}
.login-no-account-span {
  font-size: $fifteen;
  font-family: $bold;
  color: $main-color;
  margin-left: 5px;
  letter-spacing: 0.5px;
}
.Request-text{
  font-size: $fifteen;
  font-family: $bold;
  color: $main-color;
}
.login-main-text {
  position: absolute;
  height: 100%;
  font-size: 20px;
  font-family: $bold;
  width: 100%;
  z-index: 2;
  display: flex;
  top: 77%;
  color: white;
  justify-content: center;
}
.login-sub-text {
  position: absolute;
  height: 100%;
  width: calc(100% - 40px);
  font-size: $sixteen;
  font-family: $regular;
  font-weight: 400;
  z-index: 2;
  display: flex;
  margin: 0px 20px;
  top: 83%;
  text-align: center;
  flex-wrap: wrap;
  color: white;
  justify-content: center;
}
.piecharts-title {
  display: flex;
  align-items: center;
}
.MuiDivider-root {
  background-color: "#E8E9EC";
}
.list-item-active {
  display: flex;
  align-items: center;
  background-color: rgba(31, 49, 127, 0.1);
  border-radius: 5px;
  padding: 8px 0px;
  margin: 15px 5px 15px 5px;
  color: #00579b !important;
  cursor: pointer;
}
.list-item {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  margin: 15px 5px 15px 5px;
  cursor: pointer;
}
.dashboard-logo {
  height: 25px;
  width: 100%;
}
.otp-text {
  font-size: $fourteen;
  font-family: $bold;
  text-align: center;
}
.regenerate_otp {
  text-align: center;
  font-size: $fifteen;
  font-family: $bold;
  color: $main-color;
  margin: 20px 0px;
  letter-spacing: 0.5px;
}
.carsol {
  height: 92vh;
  width: 100%;
  z-index: 999;
}
.login {
  height: 100vh;
  background-color: white;
  overflow: hidden;
}
.login-without-appbar {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.otp {
  display: flex;
}

.singlevalue {
  font-size: 14px;
  font-family: "SourceSansPro-SemiBold";
  color: #293139;
  margin-top: 0px !important;
}
.singlevalue:focus {
  margin-top: 20px !important;
}
.justify-content-c {
  justify-content: center;
}
.input-field-container {
  margin-top: 20px;
}

.arn_label {
  margin-bottom: 0px;
  margin-right: 10px;
  font-size: 14px;
  color: $main-color;
  font-family: $bold;
}

.otp_input {
  border: 1px solid lightgray;
  width: 50px;
  border-radius: 5px;
  margin: 30px 10px;
  padding: 10px 10px;
}
.display-cards {
  min-width: 115px;
  max-width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(76.05deg, #6273c0 4.68%, #1f317f 125.62%);
  transform: rotate(-180deg);
}

.dashboard-my-aum {
  font-size: 18px;
  font-family: $bold;
  color: #293139;
  margin: 30px 0px 10px 20px;
}
.dashboard-my-aum-span-1 {
  font-size: $fifteen;
  // background-color: #f0f6fb;
  color: #293139;
  padding: 5px 3px;
  font-family: $regular;
  font-weight: 400;
  margin-left: 20px;
}
.dashboard-my-aum-span-2 {
  font-family: $semi-bold;
}
.dashboard-my-transaction {
  font-size: 18px;
  font-family: $semi-bold;
  margin: 20px 0px 10px 10px;
}
.dashboard-my-transaction-span-1 {
  font-size: $fourteen;
  background-color: #f0f6fb;
  color: "#303233";
  padding: 10px 10px;
  font-family: $bold;
  margin-left: 20px;
}
.dashboard-my-transaction-span-2 {
  font-family: $regular;
  font-weight: 400;
  margin-left: 30px;
}
.tabs {
  color: #606c78;
  font-size: $fifteen;
  padding: 10px 0px;
  font-family: $semi-bold;
}
.tabs-active {
  color: $main-color;
  font-size: $fifteen;
  font-family: $bold;
  padding: 10px 0px;
  border-bottom: 5px solid #da251d;
}
.MuiTableCell-head {
  padding: 30px 10px 30px 10px;
  color: #7d7d7e;
  font-family: 15px;
  font-size: $semi-bold;
}
.table-cell {
  padding: 30px 10px;
  color: #555555;
  font-family: 15px;
  font-size: $regular;
}
.tab-breadcrumbs-container {
  display: flex;
  margin: 20px;
}
.transaction-summary-label {
  color: $main-color;
  font-size: 16px;
  font-family: $semi-bold;
}
.tab-container {
  display: flex;
  justify-content: flex-start;
  margin: "25px 20px 15px 20px";
  border-bottom: 1px solid lightgray;
}
.sales-container-top {
  z-index: 999;
  position: absolute;
  top: 60px;
  left: 70px;
}
.sales-container-bottom {
  z-index: 999;
  position: absolute;
  bottom: 60px;
  left: 70px;
}
.sales-amount {
  font-size: 24px;
  font-family: $semi-bold;
  color: white;
}
.sales-label {
  font-size: $fourteen;
  font-family: $semi-bold;
  color: white;
  margin-left: 10px;
}
.sales-background-container {
  width: 250px;
  position: relative;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  // background-image: url($public_url + "/images/transaction_bg.png");
}
.sales-container {
  width: 100%;
  display: flex;
  // height: 300px;
  margin-bottom: 30px;
}
.simple-card {
  width: 33%;
  max-width: 33%;
}

.simple-card-container {
  flex-grow: 1;
}
.simple-card-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.simple-card-div-aligning{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:12px;
}
.dashboard-my-earnings {
  font-size: 20px;
  font-family: $semi-bold;
}
.display-earnings-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f4f6f9;
  padding: 15px 0px;
  margin-top: 20px;
}
.dashboard-total-earnings {
  font-size: 18px;
  font-family: $semi-bold;
  color: #555555;
  margin-left: 20px;
}
.dashboard-earnings-amount {
  color: $main-color;
  font-size: 20px;
  margin-left: 20px;
  font-family: $bold;
}
.investor-container {
  margin: 30px;
}
.investor-title {
  font-size: 20px;
  font-family: $bold;
  margin-bottom: 15px;
}
.investor-search-by {
  color: #909090;
  font-size: $twleve;
  margin-left: 20px;
  font-family: $regular;
  font-weight: 400;
}
.investor-search-radio {
  color: $main-color;
  margin-left: 20px;
}
.investor-formcontrol-label {
  margin: 0;
  display: none;
}
.investor-form-label {
  font-size: $fourteen;
  margin-left: -1px;
  display: flex;
  align-items: center;
  font-family: $regular;
  font-weight: 400;
  margin-bottom: 0px;
}
.investor-radio-button {
  display: flex;
  margin: 0px 5px;
}
.investor-paper {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  margin-top: 30px;
}
.investor-input-div {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.investor-input-searchicon {
  margin: 0px 10px;
}
.investor-input-inputbase {
  flex-grow: 1;
  margin: 0px 15px;
}
.investor-search-button {
  background-color: $secondary-color;
  cursor: pointer;
  height: 55px;
  color: white;
  font-size: $fourteen;
  font-family: $semi-bold;
  display: flex;
  align-items: center;
  padding: 0px 35px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.investor-tabs-container {
  display: flex;
  margin: 20px 0px;
  border-bottom: 1px solid #7dbbe2;
}
.investor-tabs-active {
  color: $main-color;
  font-size: $fifteen;
  font-family: $regular;
  font-weight: 400;
  padding: 10px 15px;
  border-bottom: 2px solid $main-color;
}
.investor-tabs {
  color: #7d7d7e;
  margin: 0px 20px;
  font-size: $fifteen;
  padding: 10px 15px;
  font-family: $regular;
  font-weight: 400;
}

.investor-investments-container {
  padding: 30px 40px 30px 30px;
  display: flex;
}
.investor-investments-container-title {
  font-size: 15px;
  margin-top: 10px;
  font-family: $semi-bold;
}
.investor-investments-sub-container {
  display: flex;
  padding: 15px 0px;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
}
.investor-investments-text {
  height: 42px;
  font-size: $fifteen;
  font-family: $regular;
  font-weight: 400;
  color: #919191;
  margin-bottom: 5px;
}
.investor-investments-sub-text {
  font-size: $fifteen;
  font-family: $semi-bold;
  margin-bottom: 5px;
}
.investment_details_main_top_div {
  display: flex;
  flex-direction: row;
}
.inv-right-div-split-one {
  display: flex;
}
.inv-right-div-split-two {
  display: flex;
}
.inv_detail_left_col {
  display: flex;
  flex-direction: column;
}
.small_device_fav_text {
  display: flex;
}
.investor-detail-profile-icon {
  height: 60px;
}
.pan-kyc-div {
  display: flex;
  flex-direction: row;
}
.investor-investments-fund-allocation-title {
  font-size: 18px;
  font-family: $semi-bold;
  text-align: center;
  margin: 10px 0px;
}
.investor-investments-fund-allocation-container {
  background-color: #f1f1f1;
  border-radius: 5;
  padding: 10px 50px;
  margin: 0px 20px;
}
.dummy-pie-color {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border-radius: 3px;
}
.dummy-pie-name {
  font-size: $twleve;
  display: flex;
  align-items: center;
  margin: 20px 7px 0px 7px;
  font-family: $regular;
  font-weight: 400;
}
.investors-investemnts-see-more {
  font-size: $fifteen;
  width: 70%;
  font-family: $semi-bold;
  color: $main-color;
  padding: 10px 0px 30px;
  text-align: center;
}
.transaction-type-title {
  font-size: $thirteen;
  font-family: $semi-bold;
  color: #555555;
  // margin: 30px 20px;
}
.transaction-type-cards-paper {
  padding: 15px 10px;
  display: "flex";
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 20px 20px 0px;
  width: 280px;
}
.transaction-type-cards-name {
  line-height: 22px;
  font-size: $sixteen;
  font-family: $semi-bold;
  padding: 20px 0px 15px 0px;
  text-align: center;
}
.investor-next-button {
  background-color: $main-color;
  padding: 7px 0px 9px 0px;
  width: 250px;
  text-align: center;
  color: white;
  border-radius: 3px;
  margin-top: 20px;
}

.digital-form-investor-list {
  display: flex;
  padding: 20px 10px 20px 25px;
  width: 300px;
  margin: 10px 10px;
  border-radius: 5px;
  background-color: #ecf2f7;
}

.digital-form-container {
  display: flex;
  margin: 0px 50px;
  overflow-x: hidden;
  border-bottom: 1px solid lightgray;
}
.digital-form-left-div {
  margin-top: 30px;
}
.flex {
  display: flex;
}

.digital-form-text-container {
  flex-grow: 1;
  margin: 0px 10px 0px 15px;
}
.digital-form-main-text {
  font-size: $fifteen;
  color: $main-color;
  padding-bottom: 3px;
  font-family: $bold;
}
.digital-form-sub-text {
  font-size: $thirteen;
  color: rgba(41, 49, 57, 0.7);
  margin-top: 5px;
  font-family: $regular;
  font-weight: 400;
}

.login-button-debt-button {
  border: 1px solid $main-color;
  padding: 6px 0px;
  width: 120px;
  color: $main-color;
  border-radius: 3px;
  background: transparent;
  margin: 0px 10px 10px 0px;
}
.login-button-debt-button:hover {
  background-color: #eaf4fa;
}

.digital-form-yes-button {
  display: flex;
  margin: 15px 0px;
  padding-bottom: 5px;
  border-bottom: 4px solid #67a31e;
  border-radius: 2px;
}
.digital-form-yes-button-label {
  font-family: $semi-bold;
  font-size: $fifteen;
  margin-left: 10px;
  padding-bottom: 5px;
  color: #67a31e;
}
.digital-form-no-button-label {
  font-family: $semi-bold;
  font-size: $fifteen;
  margin-left: 10px;
  padding-bottom: 5px;
}

.digital-form-Right-div-details {
  flex-grow: 1;
  padding: 40px 50px;
  border-left: 1px solid lightgray;
}
.digital-form-main-details-text {
  font-size: $thirteen;
  font-family: $bold;
  color: $main-color;
}
.digital-form-details-main-label {
  color: #7f7f7f;
  font-size: $twleve;
  font-family: $bold;
}

.digital-form-details-sub-label {
  font-size: $fourteen;
  padding-top: 5px;
  font-family: $semi-bold;
}
.digital-form-details-div {
  width: 60%;
  padding: 10px 0px;
}
.digital-form-details-next-div {
  width: 40%;
  padding: 10px 0px;
}
.digital-form-details-paper {
  padding: 15px 25px;
  margin: 20px 0px;
}

.know-about-physical-digital-button {
  background-color: #e1eff9;
  color: $main-color;
  font-size: 12px;
  padding: 15px 20px;
  font-family: $bold;
  border-radius: 5px;
  text-transform: none;
  letter-spacing: 0.3px;
  border: 0px solid;
}

.physical-digital-modes {
  position: relative;
  .descripton-about-physical-digital-mode {
    position: absolute;
    right: 50px;
    width: 350px;
    display: none;
  }
  &:hover {
    .descripton-about-physical-digital-mode {
      display: block;
    }
  }
}

.transaction-mode-img-div {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.transaction-mode-img-bg-div {
  background-color: #f3f9fc;
  padding: 15px 50px;
  border-radius: 50px;
  margin: 15px 0px;
}
.transction-mode-title {
  font-size: 18px;
  margin: "5px 0px";
  font-family: $semi-bold;
  text-align: center;
}

.transaction-mode-content {
  text-align: center;
  margin: 20px 30px;
  color: #86878b;
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
  line-height: 25px;
}

.transaction-mode-name {
  text-align: center;
  color: $main-color;
  margin: 40px 0px;
  font-size: 18px;
  font-family: $semi-bold;
}

.transaction-mode-physical-upload {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
  color: white;
}
.transaction-mode-physical-upload-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
  // margin: 15px 0px;
}

.transctionomode-physical-note {
  text-align: center;
  margin: 20px 0px 10px 0px;
  font-size: 12;
  font-family: $regular;
  font-weight: 400;
}

.investor-details-left-container {
  display: flex;
  padding: 20px 20px 0px;
}

.investor-details-name {
  font-size: 18px;
  font-family: $semi-bold;
  color: #003d7b;
  margin: 5px 0px;
}

.investor-details-pan {
  font-family: $regular;
  font-weight: 400;
  font-size: $fourteen;
  color: #424b62;
}

.investor-details-vefication-names {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
  margin-left: 5px;
}
.investor-details-vefication-div {
  background-color: #f2f8fc;
  padding: 5px 20px 7px 18px;
  border-radius: 30px;
  margin: 10px 0px;
}

.investors-details-mail-div {
  display: flex;
  align-items: center;
  margin: 15px;
}

.investors-details-buttons-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.button-highlight {
  background-color: $main-color;
  color: white;
  border: 0;
}

.investors-details-button-send-reports-investment {
  padding: 7px 0px;
  width: 200px;
  margin-left: 20px;
  font-size: $fifteen;
  font-family: $semi-bold;
  border-radius: 5px;
}

.investors-details-button-intial-investment {
  border: 1px solid $main-color;
  color: $main-color;
  padding: 7px 0px;
  width: 200px;
  font-size: $fifteen;
  font-family: $semi-bold;
  border-radius: 5px;
}

.investors-details-change-box {
  background-color: #f8fbfe;
  vertical-align: middle;
  text-align: center;
  padding: 15px 26px;
  padding-top: 25px;
  margin: 10px 5px;
}

.investors-details-change-name {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
}

.investors-details-change-percent {
  font-size: 15px;
  font-family: $bold;
  color: $main-color;
  padding: 5px 0px;
}

.investors-details-appreciation-div {
  background-color: #f8fbfe;
  vertical-align: middle;
  text-align: center;
  padding: 15px 15px;
  padding-top: 25px;
  margin: 10px 10px;
}

.investors-details-current-value {
  background-color: #f8fbfe;
  vertical-align: middle;
  text-align: center;
  padding: 15px;
  padding-top: 25px;
  margin: 10px 10px;
}

.investors-details-total-investment-div {
  background-color: #f8fbfe;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin: 10px 10px;
}

.investors-details-total-investment-name {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
}

.investors-details-total-investment-value {
  font-size: 16px;
  font-family: $bold;
  color: $main-color;
  padding: 5px 0px;
}

.investors-details-right-container {
  flex-grow: 1;
  border-left: 1px solid #ebf5fb;
  margin: 5px 0px;
}

.investors-details-fav-button-div {
  display: flex;
  border: 1px solid $main-color;
  padding: 7px 15px;
  border-radius: 5px;
  align-items: center;
}

.investors-details-fav-button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.investors-details-email {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
  margin-right: 15px;
}
.investor-details-phone-no {
  font-size: $twleve;
  font-family: $regular;
  font-weight: 400;
}

.modal-text {
  text-align: center;
  padding: 15px 0px;
  font-size: $fifteen;
  font-family: $regular;
  font-weight: 400;
}

.modal-continue-button {
  margin: 0px 150px;
}

.modal-text-container {
  padding: 10px 0px;
  background-color: #fafafc;
  margin: 30px 0px;
}

.modal-succes-msg {
  color: #34a853;
  font-size: $fifteen;
  font-family: $bold;
  text-align: center;
  margin: 30px 0px;
}

.modal-content-div {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.breadcrumbs-unselect-label {
  font-size: $fourteen;
  color: #4e4f60;
  font-family: $regular;
  font-weight: 400;
}

.amcs-paper {
  width: 191px;
  border-radius: 5px;
  height: 135px;
  border-bottom: 2px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;
  padding: 20px 0px;
}

.amcs-name {
  padding-top: 10px;
  font-size: $fourteen;
  font-family: $semi-bold;
}
.amcs-aum {
  padding-top: 5px;
  font-size: $fourteen;
  color: #828181;
  font-family: $regular;
  font-weight: 400;
}

.nav-dragger-names {
  padding: 10px 5px;
  width: 14.2%;
  text-align: center;
  color: #00579b;
  font-family: $bold;
}

.nav-dragger-name-active {
  padding: 10px 5px;
  width: 14.2%;
  background-color: #da251d;
  font-family: $bold;
  text-align: center;
  color: white;
  border-radius: 5px;
}

.nav-dragger-names-div {
  display: flex;
  justify-content: center;
  background-color: #e5eef5;
  margin: 40px 0px;
  padding: 0px 10px;
  border-radius: 5px;
}

.nav-fund-type {
  margin: 20px 0px 10px 0px;
  font-size: $fourteen;
  font-family: $regular;
  font-weight: 400;
}

.login-button-debt-button-active {
  padding: 7px 0px;
  width: 120px;
  background-color: $main-color;
  color: white;
  border-radius: 3px;
  font-family: $regular;
  font-weight: 400;
  margin: 0px 10px 10px 0px;
  border: 0;
}
.login-button-debt-button-active-no-width {
  padding: 7px 20px;
  background-color: $main-color;
  font-size: 13px;
  font-family: $regular;
  font-weight: 400;
  color: white;
  border-radius: 3px;
  border: 0;
  margin: 10px 10px 10px 0px;
}

.nav-tabs-container {
  display: flex;
  justify-content: center;
  margin: 20px 20px;
  border-bottom: 1px solid #7dbbe2;
}

.nav-tabs-active {
  color: $main-color;
  font-size: $fifteen;
  font-family: $semi-bold;
  padding: 10px 30px;
  border-bottom: 3px solid $main-color;
}
.nav-tabs {
  color: #7d7d7e;
  margin: 0px 30px;
  font-size: $fifteen;
  padding: 10px 20px;
  font-family: $regular;
  font-weight: 400;
}

.nav-left-container-graph-title {
  font-family: $semi-bold;
  font-size: $fifteen;
  padding: 20px 30px;
}

.nav-left-value {
  color: #7d7d7e;
  font-size: $twleve;
  padding: 0px 30px;
  font-family: $semi-bold;
}
.nav-left-money {
  color: $main-color;
  font-size: $twleve;
  margin: 15px 0px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  font-family: $semi-bold;
}
.nav-left-span-value {
  color: "#7d7d7e";
  margin-left: 15px;
}
.mailback-reports-div {
  display: flex;
  flex-wrap: wrap;
}
.mailback-reports-sub-reports {
  background-color: #f0f0f0;
  padding: 7px 15px;
  width: 300px !important;
  cursor: pointer;
  color: $main-color;
  margin: 10px;
  font-family: $semi-bold;
  font-size: $fifteen;
}
.mailback-reports-sub-reports :hover {
  // background-color: red;
  transition: 1s;
  box-shadow: 0px 5px 20px 0px rgba(175, 177, 177, 0.4);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.mail-back-expansion-panel {
  box-shadow: 0px 0px 3px 0px lavender;
}
.mail-back-exp-panel-summary {
  border-bottom: 1px solid #ededed;
}
.mail-back-expansion-panel-title {
  color: #2e3852;
  font-family: $semi-bold;
  font-size: $fifteen;
  margin-top: 5px;
}

.mail-back-investor-modes-container {
  margin: 40px 20px;
}
.mail-back-soa-paper {
  height: 175px;
  width: 210px;
  margin: 10px 40px 10px 0px;
}
.mail-back-modes-investor-image {
  height: 50%;
  background-color: rgba(236, 242, 247, 0.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-sp-bt {
  display: flex;
  justify-content: space-between;
}
.mail-back-investor-mode-sub-container {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mail-back-investor-mode-sub-title {
  font-family: $bold;
  font-size: $twleve;
  text-align: center;
  padding: 10px 5px;
  color: $main-text-color;
}
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mouse-pointer {
  cursor: pointer;
}

.profile-settings-right-container {
  padding: 70px 3%;
  flex-grow: 1;
  flex-basis: 0;
}
.profile-settings-left-container {
  padding: 35px 10% 20px;
  border-right: 1px solid lightgray;
  flex-grow: 1;
  flex-basis: 0;
}
.profile-settings-submit-container {
  display: flex;
  padding: 5px 0px;
  width: 180px;
  box-shadow: 0px 0px 2px 0px lavender;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}
.input-less-margin {
  margin-bottom: 15px;
}
.profile-settings-submit-year {
  margin: 0px 30px 0px 10px;
  font-size: 14px;
  font-family: $regular;
  font-weight: 400;
}
.profile-settings-review {
  font-size: 15px;
  font-family: $bold;
  margin-bottom: 30px;
  color: #5a6468;
}
.profile-settings-title {
  font-size: 18px;
  font-family: $semi-bold;
  color: #0f1010;
}

.MuiTableCell-root.table-cell {
  padding: 30px 10px 30px 20px;
  color: #2f3953;
  font-size: $fourteen;
  font-family: $regular;
  font-weight: 400;
  .user-icon {
    height: 40px;
    margin-right: 10px;
  }
  .appreciation {
    height: 20px;
    margin-left: 10px;
  }
  .kyc-fafc-verification {
    background-color: #f2f8fc;
    padding: 7px 15px 9px 12px;
    border-radius: 30px;
    .verified-icon {
      height: 8px;
      margin-left: 5px;
    }
    .verfication-text {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .phone-icon {
    height: 20px;
    margin-right: 10px;
  }
  .transaction-button {
    background-color: $main-color;
    padding: 7px 20px 9px 20px;
    color: white;
    border-radius: 3px;
    .transction-action-icon {
      height: 15px;
      padding-right: 10px;
    }
  }
}
.MuiTableCell-root.table-cell:last-child {
  padding-right: 5px;
}

.selects {
  margin: 20px 0px;
  width: 100%;
  .MuiInput-underline.Mui-error:after {
    border-bottom-width: 0px !important;
  }

  .MuiInputBase-adornedEnd {
    height: 48px;
    padding-left: 20px;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 5px;
  }
  .MuiInputBase-adornedEnd:hover {
    border: 0px solid rgb(211, 211, 211);
  }
  .MuiInputBase-root.Mui-disabled {
    border: 1px solid rgba(211, 211, 211, 1);
    border-radius: 4px;
  }
  .MuiInput-underline::before {
    border: 0px;
    border: 0px solid lightgray;
    border-radius: 5px;
    padding: 14px 10px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 0px;
    border: 1px solid lightgray;
  }
  .MuiInput-underline.Mui-focused:after {
    transform: none !important;
  }
  .jimjam {
    // border: 1px solid lightgray !important;
    border-radius: 5px;
  }
  .MuiInput-underline:after {
    border-bottom: 0px;
    transition: none;
  }
  .MuiSelect-select:focus {
    background: none !important;
  }
  .MuiSelect-selectMenu {
    height: 35px;
    display: flex;
    border: 0px solid red !important;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;
  }
  .MuiSelect-icon {
    margin-right: 3px;
  }
}
.list-div {
  .list-item-text {
    span {
      font-size: $fourteen;
      display: flex;
      justify-content: flex-start;
      margin-left: 20px;
      font-family: $semi-bold;
    }
  }
}

.about-us-a {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: -1px 1px 3px 1px lavender;
  a {
    color: black !important;
  }
  a:hover {
    color: $main-color !important;
  }
}

.about-us {
  position: relative;
  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $regular;
    font-weight: 400;
    padding: 0px 10px;
    margin-bottom: 3px;
    color: $main-color;
  }
  .dropdown-about-us {
    position: absolute;
    display: none;
    top: 25px;
    padding-top: 20px;
    height: 300px;
    width: 300px;
    background: transparent;
  }
  &:hover {
    .dropdown-about-us {
      display: block;
    }
  }
}

.client-services {
  position: relative;
  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $regular;
    font-weight: 400;
    padding: 0px 10px;
    margin-bottom: 3px;
    color: $main-color;
  }
  .dropdown-client-services {
    position: absolute;
    display: none;
    top: 25px;
    padding-top: 20px;
    height: 300px;
    width: 300px;
    background: transparent;
  }
  &:hover {
    .dropdown-client-services {
      display: block;
    }
  }
}

.client-services-a {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: -1px 1px 3px 1px lavender;
  a {
    color: black !important;
  }
  a:hover {
    color: $main-color !important;
  }
}

.information-services {
  position: relative;
  a {
    text-decoration: none;
    font-size: 14px;
    font-family: $regular;
    font-weight: 400;
    padding: 0px 10px;
    margin-bottom: 3px;
    color: $main-color;
  }
  .dropdown-information-services {
    position: absolute;
    display: none;
    top: 25px;
    padding-top: 20px;
    height: 300px;
    width: 300px;
    background: transparent;
  }
  &:hover {
    .dropdown-information-services {
      display: block;
    }
  }
}

.information-services-a {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: -1px 1px 3px 1px lavender;
  a {
    color: black !important;
  }
  a:hover {
    color: $main-color !important;
  }
}

.circular {
  max-height: 120px;
  margin-top: 30px;
  margin-right: 20px;
  svg {
    max-height: 120px;
  }
}

//Dashboard

.dashboard-main {
  .dashboard-title {
    display: flex;

    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 0px 0px lightgray;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 5;
    height: 66px;
    .dashboard-title-company {
      font-size: 18px;
      z-index: 99999;
      margin-left: 30px;
      font-family: $bold;
      color: $secondary-color;
    }
    .earnings-icon-div {
      position: absolute;
      right: 160px;
      z-index: 10;
      top: 10px;
      img {
        cursor: pointer;
        height: 35px;
      }
    }
  }
}
.home-content {
  background-image: url($public_url + "/images/jm_bg_image.svg");
  background-repeat: repeat;
  flex-grow: 1;
  overflow-x: hidden;
  // margin: 100px 30px 100px 30px;
  min-height: 92vh;
  padding: 100px 30px 100px 30px;
}

.display-small-front-cards {
  flex-wrap: wrap;
  flex-direction: column !important;
}

.display-cards-paper {
  display: flex;
  min-height: 80px;
  width: 100%;
  height: 55px;
  margin: 5px 0px !important;
  .cards-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    .cards-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .cards-value {
        font-family: $bold;
        margin-left: 10px;
        flex-grow: 1;
        flex-basis: 0px;
        font-size: 25px;
        text-align: center;
        color: $main-color;
      }
      .cards-name {
        font-family: $semi-bold;
        font-size: $sixteen;
        text-align: center;
        min-width: 75px;
        color: #6d7278;
        justify-content: flex-start;
      }
    }
  }
}
.title_dashboard {
  font-size: $sixteen;
  font-family: $bold;
  margin: 10px 30px;
  line-height: $eighteen;
  letter-spacing: 0.311111px;
  color: #293139;
}

.single-amc-header {
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  margin: 30px 10px;
  padding-bottom: 25px;
  .content {
    display: flex;
    align-items: center;
    .right-side {
      margin-left: 20px;
      .name {
        font-family: $bold;
        font-size: $fourteen;
        color: $main-color;
        text-transform: capitalize;
      }
      .pan {
        margin-top: 3px;
        font-family: $regular;
        font-weight: 400;
        color: $main-text-color;
        font-size: $thirteen;
      }
    }
  }
}

.piecharts-dashboard {
  display: flex;
  margin: 0px 10px;
  .title {
    display: flex;
    align-items: center;
    font-size: $twleve;
    padding: 10px 0px 0px;
    margin: 20px 0px 0px;
    width: 100%;
    justify-content: flex-start;
    font-family: $semi-bold;
  }
  .pie-chart-paper {
    height: 250px;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 5px;
    box-shadow: 0px 0px 3px 0px lightgray;
  }
}
.piechart-div:hover {
  background-color: #f6f6f6;
  transition: 0.5s;
}

.pie-charts-divider {
  width: 20px;
}

.pie-data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 15px;
  align-items: flex-start;
  flex-grow: 1;
}
.sub-title {
  font-family: $semi-bold;
  font-size: $eighteen;
  color: $main-text-color;
  margin: 10px;
}

.brokerage {
  margin-top: 20px;
}

.recharts-wrapper.nav-garphs {
  .recharts-surface {
    overflow: visible;
  }
}

.left-cards {
  flex-grow: 1;
}

.right-pie {
  flex-grow: 1;
  display: flex;
}
.whole-pie-data {
  display: flex;
}

.dashboard-summary-card {
  display: flex;
  align-items: center;
  max-width: 30%;
  min-width: 30%;
  padding: 16px 15px;
  margin: 0px 10px;
  margin-top: 20px;
  flex-grow: 1;
  box-shadow: 0px 0px 3px 0px rgba(211, 211, 211, 0.7) !important;
}
.dashboard-summary-card-aligning {
  display: flex;
  align-items: center;
  padding: 16px 15px;
  margin: 0px 10px;
  margin-top: 20px;
  flex-grow: 1;
  box-shadow: 0px 0px 3px 0px rgba(211, 211, 211, 0.7) !important;
}
.simple-display-card-count {
  font-size: $eighteen;
  font-family: $semi-bold;
  color: $main-text-color;
}
.list-investors-tm {
  border-bottom: 1px solid rgba(0, 87, 155, 0.3);
  margin: 0px 10px;
}

// Loader Functionality

.preloader {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.preloader .loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 50%;
  right: 50%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -75px;
  margin-top: -75px;
}

.preloader .loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 75px;
  height: 150px;
  margin-left: -75px;
  margin-top: -75px;
  overflow: hidden;
  -webkit-transform-origin: 75px 75px;
  -ms-transform-origin: 75px 75px;
  transform-origin: 75px 75px;
  -webkit-mask-image: -webkit-linear-gradient(
    top,
    $main-color,
    rgba(0, 0, 0, 0)
  );
  -webkit-animation: rotate 1.2s infinite linear;
  animation: rotate 1.2s infinite linear;
}

.preloader .loader-line-mask .loader-line {
  width: 150px;
  height: 150px;
  border-radius: 50%;

  -webkit-box-shadow: inset 0 0 0 1px $main-color;
  box-shadow: inset 0 0 0 1px $main-color;
}

.preloader .content img {
  width: 50px;
}

// Basic CSS

.right-bar-title {
  font-size: $twenty;
  font-family: $bold;
}
.right-bar-container-paper {
  overflow-x: hidden;
  margin: 30px 0px;
  border-bottom: 1px solid lightgray;
  box-shadow: 0px 2px 9px 0px rgba(24, 42, 70, 0.1) !important;
  height: 550px;
}

.digital-form-Right-div {
  flex-grow: 1;
  border-left: 1px solid lightgray;
  padding: 20px 50px;
  overflow-x: hidden;
}

.flex-row-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-grow-basis {
  flex-grow: 1;
  flex-basis: 0px;
}
.no-data {
  font-size: $fourteen;
  font-family: $semi-bold;
  color: red;
  text-align: center;
}

.m-v-15 {
  margin: 15px 0px;
}

.m-h-15 {
  margin: 0px 15px;
}

.integrated-select {
  .MuiInputBase-input {
    height: 48px !important;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  svg {
    color: $main-color;
  }
}

.transaction-details {
  .title {
    color: $main-color;
    font-size: $fifteen;
    font-family: $semi-bold;
    padding: 30px 0px;
  }
  .radio-buttons-div {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0px 10px 0px;
  }
  .redemption-details {
    font-size: $twleve;
    font-family: $regular;
    font-weight: 400;
    display: flex;
    background-color: #f4f4f4;
    margin: 15px 0px;
    justify-content: space-evenly;
    padding: 15px 0px;
    line-height: 25px;
    .keys {
      color: #747d80;
    }
    .values {
      font-family: $semi-bold;
      color: $main-color;
    }
  }
}

// Input styles

.Input-field-div {
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.4px);
  display: flex;

  align-items: center;
  border-radius: 5px;
}
.input-margin {
  margin-bottom: 20px;
}

.input-without-arn-label {
  border: 0px solid white;
  color: $input-name-color;
  outline: 0px;
  font-size: $fourteen;
  font-family: $semi-bold;
  flex-grow: 1;
}

// customizedselect CSS {
.customized-input {
  .MuiSelect-select:focus {
    border-radius: 0;
    background-color: white;
  }
  .MuiInputBase-root {
    line-height: 1.015em;
    font-size: $fourteen;
    color: $main-text-color;
    font-family: $semi-bold;
  }
  .MuiSelect-icon {
    color: $main-color !important;
    top: calc(50% - 18px);
    width: 45px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.0001) -79.17%,
      rgba(0, 142, 181, 0.08) 100%
    );
  }
  .MuiSvgIcon-root {
    height: 100%;
    font-size: 14px !important;
    margin-top: -3px;
  }
}

.heading {
  font-size: $sixteen;
  font-family: $bold;

  span {
    color: #00579b;
    font-size: $fourteen;
    font-family: $regular;
    font-weight: 400;
    margin-left: 10px;
  }
}

.transaction-tab-summary {
  margin: 25px 20px 15px 20px;
}

// Earnings CSS

.Earnings {
  .title-block {
    padding-top: 20px;
    margin-bottom: 20px;
    .heading {
      font-size: $sixteen;
      font-family: $bold;

      span {
        color: #00579b;
        font-size: $fourteen;
        font-family: $regular;
        font-weight: 400;
        margin-left: 10px;
      }
    }
    .total-amount {
      color: $secondary-color;
      font-size: $sixteen;
      font-family: $bold;
      letter-spacing: 0.3px;
    }
  }
  .earnings_block {
    margin-bottom: 10px;
    margin-top: 20px;
    .desci {
      font-size: $fourteen;
      font-family: $semi-bold;
    }
    .inner-block {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: $fourteen;
      .light-header {
        color: $light-header-color;
        font-family: $regular;
        font-weight: 400;
      }
      .float-right {
        float: right;
      }
      .amount-value {
        font-family: $semi-bold;
        color: $main-color;
      }
      .period-value {
        font-family: $regular;
        font-weight: 400;
        color: $diff-black-color;
      }
    }
  }
}

// EKYC CSS

.Ekyc {
  .instructions-box {
    border: 2px dashed black;
    margin: 0px 15px;
    padding: 10px;
  }

  .instruction-title {
    font-size: 14px;
    color: $main-color;
    font-family: $semi-bold;
    text-align: center;
    margin-bottom: 10px;
  }
  .instruction-mode {
    font-family: $semi-bold;
    padding: 3px 0px;
    color: black;
    font-size: 15px;
  }
}

// Transaction-history CSS

.transaction-history {
  .main_title {
    font-size: $fifteen;
    font-family: $bold;
    margin-bottom: 20px;
  }
  .history-card {
    padding: 10px 0px;
  }
  .main_block {
    margin: 15px 0px;
    align-items: flex-start !important;
    .title {
      font-size: $fourteen;
      font-family: $semi-bold;
      color: $main-text-color;
    }
  }
  .history_sec {
    display: flex;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
    justify-content: space-between;
  }
  .list-text {
    font-size: $fourteen;
    font-family: $regular;
    font-weight: 400;
    color: $light-header-color;
    margin-bottom: 5px;
  }
  .list-value {
    font-size: $fifteen;
    font-family: $semi-bold;
    margin-top: 2px;
  }
}

// Home - drawer CSS

.home-root {
  overflow-y: auto;
  display: flex;
  .MuiToolbar-root {
    box-shadow: 0px 0px 3px 0px lightgray;
    padding-left: 40px;
    min-height: 80px;
  }
}
.last-login-div {
  display: flex;
  margin-right: 20;
}

.drawer {
  width: 254px;
  margin-top: 80px;
  flex-shrink: 0;
  overflow: hidden;
  .MuiPaper-root {
    width: 254px;
    margin-top: 81px;
    box-shadow: 0px 0px 3px -1px lavender;
    border: 0px;
    overflow: hidden;
  }
}

.menu-logo {
  display: none;
  height: 20px;
  width: 20px;
  margin: 0px 15px;
}

//Investors - CSS
.investors-paper {
  display: flex;
}

.investor-blocks {
  display: none;
  margin-bottom: 70px;
  .investor-blocks-paper {
    padding: 10px;
    margin: 7px;
    .kyc-fafc-verification {
      background-color: #f2f8fc;
      padding: 7px 15px 9px 12px;
      border-radius: 30px;
      .verified-icon {
        height: 8px;
        margin-left: 5px;
      }
      .verfication-text {
        font-size: 12px;
        margin-left: 5px;
      }
    }
    .phone-icon {
      height: 20px;
      margin-right: 10px;
    }

    .fontCommon {
      font-size: $thirteen;

      font-family: $regular;
      font-weight: 400;
      color: $main-text-color;
    }

    .transaction-button {
      cursor: pointer;
      background-color: $main-color;
      padding: 7px 20px 9px 20px;
      color: white;
      border-radius: 3px;
      .transction-action-icon {
        height: 15px;
        padding-right: 10px;
      }
    }
    .user-block-icon {
      height: 40px;
      margin-right: 10px;
    }
  }
}

// Nav - data

.nav-table-margin {
  margin: 50px 100px;
}

.mobile-aum-list {
  display: none;
}

.investor-types-table {
  display: block;
}

.profile-setting-page {
  .inner-profile-sec {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }
}

// Media Queries

@media screen and (max-width: 1600px) {
  .login-right-div {
    width: 50%;
    padding: 70px 100px 170px 100px;
  }
}

@media screen and (max-width: 1200px) {
  .login-right-div {
    width: 50%;
    padding: 70px 75px 170px 75px;
  }
  .drawer {
    width: 225px;
    .MuiPaper-root {
      width: 225px;
    }
  }
  .display-cards {
    min-width: 120px;
    max-width: 120px;
  }

  .piechart-div {
    .recharts-wrapper {
      width: 160px !important;
    }
  }
  .home-content {
    margin: 100px 10px;
  }

  .MuiTableCell-root.table-cell {
    padding: 40px 8px 30px 8px;
    font-size: 12px;
    .user-icon {
      height: 25px;
      margin-right: 5px;
    }
    .appreciation {
      height: 10px;
      margin-left: 5px;
    }
    .kyc-fafc-verification {
      background-color: #f2f8fc;
      padding: 7px 5px 9px 5px;
      border-radius: 30px;
      .verified-icon {
        height: 5px;
        margin-left: 3px;
      }
    }
    .phone-icon {
      height: 15px;
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .login-right-div {
    width: 50%;
    padding: 70px 50px 170px 50px;
  }
  .otp_input {
    width: 42px;
  }
  .carsouel-div {
    width: 60%;
  }
  .piecharts-dashboard {
    display: block;
  }
  .pie-charts-divider {
    width: 0px;
  }
  .right-pie {
    margin-top: 20px;
  }
  .dashboard-summary-card {
    max-width: 47%;
    min-width: 47%;
    padding: 8px 10px;
    box-shadow: 0px 0px 3px 0px rgba(211, 211, 211, 0.3);
  }
  .simple-display-card-image {
    height: 45px;
  }
  .investor-blocks {
    display: block;
  }
  .investors-paper {
    display: none;
  }
  .nav-left-container {
    width: 50%;
    padding: 30px 30px;
  }
  .nav-table-margin {
    margin: 50px 30px;
  }
}
@media screen and (max-width: 1072px) {
  .otp {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .right-bar-container-paper {
    height: 830px;
  }
  .login-right-div {
    width: 60%;
    padding: 70px 30px 170px 30px;
  }
  .otp_input {
    width: 45px;
    margin: 28px 7px;
  }
  .carsouel-div {
    width: 55%;
  }
  .drawer {
    display: none;
  }
  .home-root {
    .MuiToolbar-root {
      padding-left: 0px;
    }
  }
  .last-login-div {
    display: none;
  }
  .menu-logo {
    display: block;
  }
  .piecharts-dashboard {
    display: block;
  }
  .home-content {
    margin: 100px 30px;
  }
  .nav-left-container {
    width: 100%;
    padding: 30px 30px;
  }
  .nav-right-container {
    width: 100%;
  }
  .nav-table-margin {
    margin: 50px 30px;
  }
  .profile-setting-page {
    .inner-profile-sec {
      display: block;
    }
    .profile-flex {
      display: block !important;
    }
  }
  .carousel-control-prev {
    bottom: 30% !important;
  }
  .carousel-control-next {
    bottom: 30% !important;
  }
  .otp {
    display: block;
  }
}
.dashboard-title-responsive {
  padding: 10px;
  background: white;
  box-shadow: 0px 0px 3px 0px lightgray !important;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  .dashboard-title-company {
    font-size: 14px;
    z-index: 99999;
    font-family: $bold;
    color: $secondary-color;
  }
}

@media screen and (max-width: 768px) {
  .simple-card-div-aligning{
    grid-template-columns: 1fr 1fr;

  }
 
  .login-right-div {
    width: 100%;
    padding: 70px 50px 170px 50px;
  }
  .otp {
    display: flex;
  }
  .carsouel-div {
    display: none;
  }
  .dashboard-main {
    .dashboard-title {
      display: none;
    }
    .dashboard-title-responsive {
      display: block;
    }
  }

  .dashboard-summary-card {
    max-width: 45%;
    min-width: 45%;
    padding: 8px 10px;
    box-shadow: 0px 0px 3px 0px rgba(211, 211, 211, 0.3);
  }
  .simple-display-card-name {
    font-size: $twleve;
  }
  .simple-display-card-count {
    font-size: $fourteen;
  }

  .two-div {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .input-name {
    margin-top: 15px;
  }
  .right-bar-container-paper {
    background-color: transparent !important;
    box-shadow: 0px 0px 0px 0px !important;
    border-bottom: 0px;
    display: block !important;
  }
  .digital-form-investor-list {
    width: 100%;
    margin: 10px 0px;
  }
  .digital-form-Right-div {
    padding: 10px 0px;
    border-left: 0px;
  }
  .tabs {
    font-size: 11px;
  }
  .tabs-active {
    font-size: 12px;
  }
  .digital-form-button-next-div {
    margin: 20px 0px;
  }
  .dual-margin {
    margin: 0px;
  }
  .tab-breadcrumbs-container {
    li {
      margin-top: 10px;
    }
  }
  .list-investors-tm {
    margin: 10px 0px;
  }
  .nav-left-container {
    width: 100%;
    padding: 30px 0px;
  }
  .nav-table-margin {
    margin: 50px 10px;
  }
  .mobile-aum-list {
    display: block;
  }
  .investor-types-table {
    display: none;
  }
  .MuiDialog-paperFullWidth {
    margin: 4px !important;
    width: 100% !important;
  }
  .profile-setting-page {
    .inner-profile-sec {
    }
  }
  .trasacation-summary-responsive {
    display: none;
  }
  .transcation-summary-responsive-page {
    padding: 0px;
  }
  .tranacation-history-title {
    display: flex;
    flex-direction: row;
  }
  .digital-form-Right-div-responsive {
    display: none;
  }
  .bc-responsive {
    width: 100%;
  }
}

.nav-main-container-margin {
  margin: 30px 0px;
}


@media screen and (max-width: 576px) {
  .simple-card-div-aligning{
    grid-template-columns: 1fr;
  }
  .username-disabled {
    display: none;
  }
  .appbardiv {
    .jmf-name {
      display: none;
    }
  }
  .transaction-tab-summary {
    margin: 10px;
  }
  .home-content {
    padding: 72px 2px;
  }
  .investor-name {
    font-size: 12px !important;
  }
  .display-cards {
    max-width: 85px;
    min-width: 85px;
  }
  .display-cards-paper {
    .cards-details {
      .cards-content {
        display: block !important;
      }
    }
  }

  .login-right-div {
    width: 100%;
    padding: 70px 20px 170px 20px;
  }
  .carsouel-div {
    display: none;
  }
  .home-content {
    margin: 15px 10px;
  }
  .display-cards-paper {
    .cards-details {
      height: 100%;
      justify-content: center;
      .cards-content {
        .cards-value {
          font-size: $sixteen;
        }
        .cards-name {
          font-size: $thirteen;
        }
      }
    }
  }
  .piecharts-dashboard {
    .whole-pie-data {
      display: flex;
    }
    .pie-chart-paper {
      height: 100% !important;
    }
    .piechart-div {
      display: block !important;
      .recharts-wrapper {
        width: 100% !important;
        display: flex;
        justify-content: center;
      }
    }
  }
  .recharts-wrapper.nav-garphs {
    .recharts-surface {
      overflow: visible;
      width: 300px;
    }
  }
  .dashboard-summary-card {
    width: 100%;
    max-width: 100%;
    margin: 10px;
  }
  .transaction-details {
    .mailback-reports-sub-reports {
      width: 100%;
    }
  }

  .Earnings {
    .title-block {
      flex-direction: column;
      align-items: flex-start;
    }

    .earnings_block {
      .desci {
        font-size: $thirteen;
      }
      .inner-block {
        .period-value {
          font-size: $thirteen;
        }
      }
    }
  }
  .mail-back-investor-modes-container {
    margin: 40px 10px;
    .MuiPaper-root {
      width: 100%;
    }
    .inner-mailback-investor {
      justify-content: center;
    }
    .mail-back-soa-paper {
      margin: 10px 0px 10px 0px;
    }
  }

  .all-investors-view {
    flex-direction: column;
  }
  .investor-blocks {
    .investor-blocks-paper {
      .phone-icon {
        display: none;
      }
      .fontCommon {
        .span-calss {
          display: none;
        }
      }
      .kyc-fafc-verification {
        background-color: #f2f8fc;
        display: flex;
        align-items: center;
        padding: 7px 5px 9px 5px;
        border-radius: 30px;
        .verified-icon {
          height: 5px;
          margin-left: 3px;
        }
      }

      .transaction-button {
        cursor: pointer;
        min-width: none;
        padding: 7px;
        font-size: 10px;
        img {
          display: none;
        }
      }
      .user-block-icon {
        height: 0px;
        // margin-right: 10px;
      }
    }
  }
  .otp {
    display: flex;
  }
  .digital-form-details-div {
    width: 100%;
  }
  .mobile-aum-list {
    display: block;
  }
  .investor-types-table {
    display: none;
  }
  .logo {
    // width: 150px;
    height: 45px;
  }
  .nav-tabs-container {
    margin: 10px 20px 5px 20px !important;
  }
  .nav-left-container {
    padding: 5px 0px;
  }
  .nav-main-container-margin {
    margin: 5px 0px;
    .investor-title {
      margin-bottom: 5px !important;
    }
  }
  .digital-form-Right-div {
    margin-top: 5px !important;
  }
  .right-bar-container-paper {
    margin-top: 10px !important;
  }
  .transaction-details {
    .ekyc-margin {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  .tabs-active {
    padding: 0px;
  }
  .investors-inactive-active-responsive {
    padding: 0px;
  }
  .investor-blocks .investor-blocks-paper {
    padding: 7px;
    margin: 10px 0px;
  }
  .tab-container-new-active-inactive {
    margin: 0px;
  }
  // .modal-continue-button {
  //   width:100%;
  //   height:100%;
  // }
  .modal-continue-button {
    margin: 0px;
  }
  .login-button-sign-in {
    width: 100%;
  }
  .close-margin {
    margin-top: 15px !important;
  }
  .container-margin {
    margin: 10px;
  }

  .otp-responsive {
    display: flex !important;
    justify-content: center !important;
  }
  .ttcp-responsive {
    margin: 10px 0px !important;
  }

  .bread-crumbs {
    margin: 5px !important;
  }
  .modal-responsive {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .modal-continue-button {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .trasacation-summary-responsive {
    display: none;
  }
  .transcation-summary-responsive-page {
    padding: 0px;
  }
  .tranacation-history-title {
    display: flex;
    flex-direction: row;
  }
  .digital-form-Right-div-responsive {
    display: none;
  }
  .bc-responsive {
    width: 100%;
  }
  .responsive-split-one {
    display: flex;
    flex-direction: column;
  }
  .investment_details_main_top_div {
    flex-direction: column;
  }
  .inv-right-div-split-two {
    flex-direction: column;
  }
  .investors-details-total-investment-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .investor-investments-container-title {
    display: flex;
  }
  .investor-investments-container {
    flex-direction: column;
  }
  .investor-investments-fund-allocation-container {
    margin: 0px;
    padding: 0px;
  }
  .whole-pie-data {
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
  }
  .investors-details-buttons-div {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
  .otp_input {
    width: 41px;
    margin: 28px 3px;
  }
  .investor-container {
    margin: 10px;
  }
  .investor-input-inputbase {
    margin: 0px;
  }
  .transaction-details {
    height: 500px;
  }
  .Input-field-div {
    padding: 12px;
  }
}
